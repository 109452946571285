import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'

import Layout from '../layouts/default'
import SEO from '../components/seo'
import Container from '../components/common/container'
import RichText from '../components/richText'
import FileIcon from '../icons/file.svg'

import { mediaQuery } from '../styles/base'
import colors from '../styles/colors'

const FileContainer = styled.div`
  display: flex;
  ${mediaQuery({
    justifyContent: ['center', 'flex-end', null],
    margin: ['0', '30px 0', null],
  })}
`
const FileLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: ${colors.inverted.primary};
`

function ProjectTemplate({ data }) {
  const { project } = data

  return (
    <Layout>
      <SEO title={project.title} />
      <Container variant="primary">
        <div className="container-fluid">
          <div className="row">
            <section className="col-xs-12">
              <h1>{project.title}</h1>
              <RichText data={project.details} />
              <FileContainer>
                <FileLink href={project.document?.url}>
                  Ver archivo
                  <FileIcon
                    fill={colors.inverted.primary}
                    height="40px"
                    width="40px"
                  />
                </FileLink>
              </FileContainer>
            </section>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

ProjectTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query ProjectDataQuery($id: String) {
    project: contentfulContentProject(id: { eq: $id }) {
      title
      details {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(placeholder: BLURRED, width: 800)
            __typename
          }
          ... on ContentfulFragmentImageText {
            contentful_id
            image {
              description
              gatsbyImageData(placeholder: BLURRED)
            }
            text {
              raw
            }
            layout
            __typename
          }
          ... on ContentfulFragmentPhotoSlider {
            contentful_id
            photos {
              description
              gatsbyImageData(placeholder: BLURRED, height: 600)
            }
            __typename
          }
        }
      }
      document {
        url
      }
    }
  }
`

export default ProjectTemplate

import styled from '@emotion/styled'

import ImageText from '../imageText'
import { Section, ImageSection } from '../imageText/style'
import { mediaQuery } from '../../styles/base'
import colors from '../../styles/colors'

export const Container = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: ${colors.inverted.primary};
  }
`

export const Link = styled.a`
  color: ${colors.inverted.primary};
  font-weight: normal;
  text-decoration: underline;
`

export const Blockquote = styled.blockquote`
  color: ${colors.inverted.primary};
`

export const EmbedImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`

export const EmbedImageText = styled(ImageText)`
  padding: 0;
  margin-bottom: 1rem;

  > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  ${Section} {
    padding-left: 0;
  }

  ${ImageSection} {
    padding-right: 0;
  }

  article {
    height: 100%;
  }
`

export const FileContainer = styled.div`
  display: flex;
  ${mediaQuery({
    justifyContent: ['center', 'flex-end', null],
    margin: ['0', '30px 0', null],
  })}
`
export const FileLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: ${colors.inverted.primary};
`

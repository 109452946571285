// TODO: Merge this with Hero component
// once we upgrade everything to gatsby-plugin-image
import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import {
  Container, StyledSlider, DotsContainer, SlideContainer,
} from './style'

import { heroPropType } from '../../types/propTypes'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const generateSliderImage = ({ gatsbyImageData, description }, idx) => (
  <React.Fragment key={idx}>
    <SlideContainer image={gatsbyImageData}>
      <GatsbyImage image={gatsbyImageData} alt={description} />
    </SlideContainer>
  </React.Fragment>
)

generateSliderImage.propTypes = heroPropType

function StyledDots(dots) {
  return <DotsContainer>{dots}</DotsContainer>
}

function PhotoSlider({ displayList }) {
  return (
    <Container>
      <StyledSlider
        dots
        appendDots={StyledDots}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        swipeToSlide
      >
        {displayList.map(generateSliderImage)}
      </StyledSlider>
    </Container>
  )
}

PhotoSlider.propTypes = {
  displayList: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      gatsbyImageData: PropTypes.object.isRequired,
    }),
  ).isRequired,
}

export default PhotoSlider

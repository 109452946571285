import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'

import PhotoSlider from '../photoSlider'
import {
  Container,
  Link,
  Blockquote,
  EmbedImageContainer,
  EmbedImageText,
} from './style'

// Options to determine rendering of Rich Text from Contentful
const richTextOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { data } = node
      return <Link href={data.uri}>{children}</Link>
    },
    [BLOCKS.QUOTE]: (_, children) => {
      if (React.Children.count(children) === 1) return <Blockquote>{children}</Blockquote>

      const content = React.Children.toArray(children)
      const cite = content.pop()

      return (
        <Blockquote>
          {content}
          <cite>{cite}</cite>
        </Blockquote>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { data } = node
      const { gatsbyImageData, description } = data.target

      return (
        <EmbedImageContainer>
          <GatsbyImage image={gatsbyImageData} alt={description} />
        </EmbedImageContainer>
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { data } = node
      // eslint-disable-next-line no-underscore-dangle
      const renderOption = richTextOptions.renderNode[data.target.__typename]
      if (renderOption) return renderOption(node)

      return undefined
    },
    ContentfulFragmentImageText: (node) => {
      const { data } = node
      const { image, text, layout } = data.target
      const imageEl = (
        <GatsbyImage
          key="image"
          image={image.gatsbyImageData}
          alt={image.description}
        />
      )
      const textEl = (
        <article key="text">
          {text && renderRichText(text, richTextOptions)}
        </article>
      )

      let elements = []
      if (layout === 'Text/Image') elements = [textEl, imageEl]
      if (layout === 'Image/Text') elements = [imageEl, textEl]

      return <EmbedImageText>{elements}</EmbedImageText>
    },
    ContentfulFragmentPhotoSlider: (node) => {
      const { data } = node
      const { photos } = data.target

      return <PhotoSlider displayList={photos} />
    },
  },
}

function RichText({ className, containerEl, data }) {
  const RichTextContainer = Container.withComponent(containerEl)
  return (
    <RichTextContainer className={className}>
      {data && renderRichText(data, richTextOptions)}
    </RichTextContainer>
  )
}

RichText.propTypes = {
  className: PropTypes.string,
  containerEl: PropTypes.string,
  data: PropTypes.shape({
    raw: PropTypes.string.isRequired,
    references: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}

RichText.defaultProps = {
  className: undefined,
  containerEl: 'article',
}

export default RichText

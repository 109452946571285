import styled from '@emotion/styled'
import Slider from 'react-slick'

import colors from '../../styles/colors'

export const Container = styled.section``

export const StyledSlider = styled(Slider)`
  margin-bottom: 40px;
`

export const DotsContainer = styled.ul`
  &.slick-dots {
    li {
      button:before {
        color: ${colors.inverted.primary};
      }

      &.slick-active button:before {
        color: ${colors.inverted.primary};
      }
    }
  }
`

export const SlideContainer = styled.article`
  position: relative;
  margin: 0 auto;
  max-width: ${({ image }) => `${image.width}px`};
`
